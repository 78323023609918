#print-request {
  padding-top: 1.5rem;
  padding-bottom: 5rem;

  .action-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .btn-logout {
      width: max-content;
    }

    .btn-back {
      border: none;
      background-color: transparent;
      color: #000;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  .select {
    appearance: auto;
    height: 38px;
  }

  .check-status-btn {
    margin-top: 1.5rem;
  }

  .logout-btn {
    margin-top: 0.75rem;
    background-color: #fff;
    color: #000;

    &:hover:not(:disabled) {
      background-color: #c3cfd9;
      color: #fff;
      border-color: #c3cfd9;
    }
  }

  .detail {
    .image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f3f3f3;
      height: 372px;

      @media (max-width: 766px) {
        height: 250px;
        margin-bottom: 15px;
      }

      img {
        width: 20%;
      }

      img.images {
        width: auto;
        height: 100%;
      }
    }

    .card {
      height: 100%;
    }

    .form-info {
      display: flex;
      align-items: center;
      margin-top: 15px;

      .left,
      .right {
        width: 50%;
      }
      .action {
        margin-right: 10px;
      }
    }

    .hangtag {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .title {
        margin-right: 15px;
      }
      a {
        color: #6558f5;
        margin-right: 15px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .report-btn {
      margin-top: 0.75rem;
      background-color: #d3455b;
      border-color: #d3455b;
      color: #fff;

      &:hover:not(:disabled) {
        opacity: 0.7;
      }

      &.comment-btn {
        background-color: #000;
        border-color: #000;
      }
    }

    .ship-ready-btn {
      margin-top: 0.75rem;
      background-color: #1aae9f;
      border-color: #1aae9f;
      color: #fff;

      &:hover:not(:disabled) {
        opacity: 0.7;
      }
    }

    .report {
      .item {
        margin-bottom: 20px;
        label {
          margin-bottom: 10px;
        }

        .send-btn {
          background-color: #6558f5;
          border-color: #6558f5;
          &:hover {
            color: #fff;
            opacity: 0.7;
          }

          @media (max-width: 766px) {
            margin-top: 15px;
          }
        }
      }
    }
    .history {
      .card {
        margin-top: 10px;

        &.ready-to-print {
          background-color: #dee6ee;
          border: none;
        }
        .card-body {
          padding: 5px 10px;
          .item {
            span:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .enter-code {
    .card {
      .card-body {
        .row {
          margin-top: 15px;
          label {
            width: 100%;
            height: 100%;
            padding: 0 0.5rem;
            white-space: nowrap;
          }

          .bottom {
            margin-bottom: 30px;
          }

          .col-md-6:nth-child(2n) {
            label {
              text-align: right;
            }
          }

          @media (max-width: 766px) {
            .col-md-6:nth-child(2n) {
              label {
                text-align: left;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .change-select {
    margin-top: 20px;

    .text {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 766px) {
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }

    .regenerate-hangtags-btn {
      font-size: 14px;
    }
  }
}

.btn-go-detail {
  background-color: #000;
  color: #fff;
  width: 94px;
  margin-left: 10px;
}

.apply-btn {
  background-color: #000;
  border-color: #000;
  color: #fff;

  &:hover:not(:disabled) {
    background-color: #fff;
    border-color: #000;
    color: #000;
  }
  &:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }

  @media (max-width: 766px) {
    margin-top: 15px;
  }
}

.info-icon {
  width: 16px;
  height: 16px;
  position: relative;
  top: -2px;
  cursor: pointer;
  left: 5px;
}

.show {
  display: block;
}

.modal {
  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-body {
    padding: 10px 100px;
    text-align: center;
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
}

.table {
  margin-top: 20px;
  width: 100%;

  table {
    width: 100%;

    thead {
      tr {
        border: 1px solid #909090;
        th {
          border-right: 1px solid #909090;
          padding: 5px 10px;
        }
      }
    }
    tbody {
      tr {
        border: 1px solid #909090;
        td {
          border-right: 1px solid #909090;
          padding: 5px 10px;
        }
      }
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sort {
    margin-left: 10px;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.image {
  width: 64px;
  height: 96px;
  > img {
    width: 100%;
    height: 100%;
  }
}

.image-stack {
  margin-left: 25%;
  margin-right: 25%;
  > img {
    width: 100%;
    height: 100%;
  }
}
