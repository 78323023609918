#my-pricing {

	font-family: "CircularStd-Book";

	.default-btn {
		border-radius: 4px;
		border: 1px solid #101010;
		background-color: #fff;
		color: #101010;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		font-family: "CircularStd-Book";

		.primary-btn {
			background: #AFAFAF;
		}

		&.disabled {
			color: #fff;
			border: 1px solid #AFAFAF;
		}
	}

	.margin-right-10 {
		margin-right: 10px;
	}

	.my-pricing-header {
		text-align: center;
		margin-top: 30px;

		.ant-divider {
			background-color: #101010;
			width: 60%;
			min-width: 50%;
			text-align: center;
			margin: auto;
			margin-bottom: 24px;
		}

		h1 {
			font-family: Garamond Premier Pro;
			font-style: normal;
			font-weight: 600;
			font-size: 36px;
		}

		span {			
			font-style: normal;
			font-weight: 400;
			font-size: 14px;

			a {
				font-family: "CircularStd-Bold";
				color: #000;
			}
		}
	}

	.my-pricing-body {
		.table-actions {
			margin-top: 60px;
			display: flex;
			justify-content: space-between;

			.actions-left {
				.info-section {
					margin-top: 10px;
				}
			}

			.actions-right {
				svg {
					width: 10px;
					height: 10px;
				}

				.ant-input-affix-wrapper {
					background-color: #ECECEC;
					font-size: 12px;

					input {
						background-color: #ECECEC;
						font-size: 12px;
						color: #646464;
						&::-webkit-input-placeholder { /* Edge */
							color: #646464 !important;
						}
						
						&:-ms-input-placeholder { /* Internet Explorer 10-11 */
							color: #646464 !important;
						}
						
						&::placeholder {
							color: #646464 !important;
						}
					}
				}

				.download-btn {
					float: right;
					margin-top: 10px;
				}
			}

			.pending-change {
				padding-left: 10px;
				font-style: italic;
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.2px;
				color: #646464;
				border-left: 1px solid #ECECEC;

				&.error {
					color: #C86254;
					font-style: normal;
				}

				&.underline {
					text-decoration: underline;
				}
			}

			.successful-change {
				padding-left: 25px;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.2px;
				color: #51AA9F;
				border-left: 1px solid #ECECEC;
				position: relative;

				svg {
					position: absolute;
					top: -2px;
					left: 7px;
				}
			}
		}

		.my-pricings-table {
			margin-top: 10px;

			.ant-table-thead {
				tr {
					.ant-table-cell:nth-child(3),
					.ant-table-cell:nth-child(6),
					.ant-table-cell:nth-child(7) {
						background-color: #F6F6F6;
					}

					.ant-table-cell {
						background-color: #fff ;
						text-align: center;
						text-transform: uppercase;
						border-bottom: 2px solid #ECECEC;
						border-top: 2px solid #ECECEC;
						font-style: normal;
						color: #969696;
						font-weight: 400;
						font-size: 12px;
						white-space: nowrap;

						&::before {
							content: unset;
						}
					}
					
					.profit-header {
						display: flex;
						flex-direction: row;
						justify-items: baseline;
						align-items: baseline;

						svg {
							margin: auto;
						}
					}

				}
			}

			.ant-table-tbody {
				tr {

					.ant-table-cell {
						border-bottom: 2px solid #ECECEC;
						text-align: center;

						.price-column {
							display: flex;
							flex-direction: column;
							justify-content: center;

							.currency-input {
								margin: auto;
							}
						}

						.calendar-column {
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
							flex-direction: column;

							.error-message {
								padding-left: 13px;
							}
						}

						.error {
							color: #C86254;

							.ant-input-suffix {
								color: #C86254;
							}

							input {
									color: #C86254;

									&::-webkit-input-placeholder { /* Edge */
										color: #C86254 !important;
									}
									
									&:-ms-input-placeholder { /* Internet Explorer 10-11 */
										color: #C86254 !important;
									}
									
									&::placeholder {
										color: #C86254 !important;
									}
							}
						}

						.discount-expiry-colum {
							display: flex;
							flex-direction: column;							
						}

						.error.discount-expiry-colum {
							.calendar {
								padding-left: 30px;								
							}
							.message {
								svg {
									left: -12px;
								}
							}
						}
					
						.error-message {
							font-style: normal;
							font-weight: 400;
							font-size: 8px;
							line-height: 10px;
							position: relative;
							margin-top: 5px;

							svg {
								position: absolute;
								left: 0px;
								top: -1px;
							}
						}

						.title-section {
							width: 100%;
						}

						.no-image {
							width: 60px;
						}
						
						.pdt-10 { 
							padding-top: 10px;
						}
					}
				}
			}

			.canvas-title {
				display: flex;

				img {
					width: 38px;
					margin-right: 8px;
				}

				.title {
					font-weight: bold;
					font-size: 14px;
				}

				.canvas-number {
					font-size: 10px;
				}
			}

			.discount-expiry {
				color: #101010;
				font-size: 14px;
			}

			.discount-expiry-selector {
				color: #AFAFAF;
				font-size: 14px;
			}
		}
	}

	.ant-table-cell-row-hover {
		background-color: #fff;
	}

	.currency-input {
		border: unset;
		background-color: #fff;
		border-bottom: 1px solid #AFAFAF;
		width: 100px;
		color: #101010;
		text-align: center;

		&.display-price {
			display: flex;
			align-items: baseline;
			width: 100%;
			justify-content: center;

			svg { 
				margin-left: 5px;
			}
		}

		&::selection {
			color: #101010;
		}

		&:focus{
			box-shadow: unset;
		}

		input{
			&::-webkit-textfield-decoration-container {
				display: flex;
				flex-direction: column;
			}
		}
	}

	.ant-input-affix-wrapper-focused {
		box-shadow: unset;
	}

	.discount-expiry-picker {
		&.ant-picker-focused {
			box-shadow: unset;
		}

		border: unset;
		
		.ant-picker-suffix {
			display: none;
		}
	}

	.apply-btn {
		background: #101010;
		border: 1px solid #101010;
		border-radius: 4px;
		color: #fff;

		&.disabled,
		&.disabled:hover {
			background: #AFAFAF;
			border: 1px solid #AFAFAF;
			background-color: #C86254;
		}

		&.active {
			background-color: #51AA9F;
			border-color: #51AA9F;
		}
	}

	.default-btn {
		&:hover {
			background-color: #101010;
			color: #fff;
		}
	}

	.ant-modal-title {
		font-family: 'Circular Std';
		font-style: normal;
		font-weight: 400;
		font-size: 22px;
	}

	.ant-modal-body {
		padding: 32px 32px;
	}
	
}

.my-pricing-modal {
	margin: auto;
	width: 632px !important;
	font-family: "CircularStd-Book";

	.ant-modal-close-x {
		width: 14px;
    height: 14px;
    margin-top: 32px;
    margin-right: 32px;

		svg{
			image {
				width: 14px;
				height: 14px;
			}
		}
		
	}

	.ant-modal-header {
		padding: 32px 32px;
		border-bottom: unset;

		.ant-modal-title {
			font-weight: bold;
			font-size: 22px;
			color:#101010;
		}
	}

	.ant-modal-body {
		padding-top: unset;
		padding-left: 32px;
		padding-right: 32px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		color: #646464;

		.discount-input-form-section {
			padding: 0px 10px;

			.discount-expiry-picker {
				width: 100%;
			}
		}

		.ant-input-number {
			width: 100%;
		}

		.error {
			color: #C86254;

			.discount-expiry-picker {
				border-color: #C86254;
			}
		}

		.canvas-title {
			display: flex;

			img {
				width: 38px;
				margin-right: 8px;
			}

			.title {
				font-weight: bold;
				font-size: 14px;
			}

			.canvas-number {
				font-size: 10px;
			}
		}

		.form-check-input.checked-all {
			margin-left: 16px;
		}

		.table-actions {
			display: flex;
			justify-content: space-between;

			.actions-left {
				.info-section {
					margin-top: 10px;
				}
			}

			.actions-right {
				svg {
					width: 10px;
					height: 10px;
				}

				.ant-input-affix-wrapper {
					background-color: #ECECEC;
					font-size: 12px;

					input {
						background-color: #ECECEC;
						font-size: 12px;
						color: #646464;
						&::-webkit-input-placeholder { /* Edge */
							color: #646464 !important;
						}
						
						&:-ms-input-placeholder { /* Internet Explorer 10-11 */
							color: #646464 !important;
						}
						
						&::placeholder {
							color: #646464 !important;
						}
					}
				}
			}
		}
	}

	.ant-modal-footer {
		padding-right: 32px;
    padding-bottom: 32px;

		.ant-btn {
			background: #F6F6F6;
			border: 1px solid #F6F6F6;
			box-sizing: border-box;
			border-radius: 4px;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			color: #646464;

			&:hover {
				background-color: #101010;
				color: #fff;
			}

			&.ant-btn-primary {
				background: #101010;
				border: 1px solid #101010;
				box-sizing: border-box;
				border-radius: 4px;
				color: #fff;
			}

			&.submit {
				background: #101010;
				border: 1px solid #101010;
				box-sizing: border-box;
				border-radius: 4px;
				color: #fff;
			}
		}
	}
}