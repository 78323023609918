#order-detail {
  padding-top: 1.5rem;
  padding-bottom: 5rem;

  .logo-icon {
    width: 72px;
    height: 122px;
    position: relative;
    left: -22px;
  }

  .order-detail__top {
    .order-detail__top__left {
      font-size: 20px;

      .item {
        font-family: $textFontBold;

        .value {
          font-family: $textFont;

          &.address {
            font-size: 17px;
          }
        }
      }
    }

    .order-detail__top__right {
      padding-top: 35px;
    }
  }

  .btn-mark-complete {
    background-color: #00b19f !important;
    width: 182px;
    color: #fff !important;
    font-family: $textFont !important;
  }

  .link{
    cursor: pointer;
  }
}

#bundledModal {
  .modal-header {
    padding-left: 32px;
    padding-right: 32px;
  }
  .modal-body {
    padding-left: 32px;
    padding-right: 32px;
  }

  .selected-pre-section-title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 15px;
    letter-spacing: 0.2px;
    color: #969696;
    text-align: left;
  }

  .product-detail {
    display: flex;
    text-align: left;

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
    }

    .product-sku {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.2px;
      color: #969696;
    }

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: #101010;
      margin-bottom: 6px;
    }
  }

  .selected-pr-section {
    background-color: #F7F7F8;
    padding: 16px 16px;
    margin-bottom: 32px;
  }

  .bundled-product-section {
    text-align: left;
    .heading {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #646464;
      margin-bottom: 26px;

      .green-line {
        color:#51AA9F;
      }
    }

    .picking-row {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .checkbox-inp {
        margin-right: 16px;
      }
    }
    
  }

  .checkbox-inp {
    width: 14px;
    height: 14px;

    &:checked {
      background-color: #51AA9F;
      border-color: #51AA9F;
    }
  }

  .modal-footer {
    justify-content: right;

    .footer-action {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2px;

      .skip-btn {
        text-align: right;
        color: #969696;
        margin-right: 16px;
      }

      .confirm-btn {
        background: #101010;
        border: 1px solid #101010;
        color: #fff;
      }
    }
  }
  
}