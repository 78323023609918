#sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  form {
    width: 500px;
  }

  .default-btn {
    margin-top: 1.5rem;
  }
}
