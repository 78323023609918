#customer {
  .row {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  select {
    appearance: menulist-button;
    border-color: #1d8bfa;
  }
  button.save {
    width: 120px;
  }
}
