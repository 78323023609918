@font-face {
  font-family: "CircularStd-Bold";
  src: url("../fonts/CircularStd-Bold.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("../fonts/CircularStd-Book.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "Garamond Premier Pro Regular";
  src: url("../fonts/GaramondPremrPro.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Breathing";
  src: url("../fonts/Breathing.ttf") format("truetype");
  font-style: normal;
}

@import 'antd/dist/antd.css';

@import "./utils/utils.scss";
@import "./pages/sign-in.scss";
@import "./pages/print-request.scss";
@import "./pages/product-error.scss";
@import "./pages/order-detail.scss";
@import "./pages/manage-prices.scss";
@import "./pages/customer.scss";

*,
::after,
::before {
  box-sizing: border-box;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.App {
  width: 100%;
  min-height: 100vh;
  padding: 0 10px;

  .container {
    width: 100%;
    height: 100vh;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

img {
  object-fit: cover;
  object-position: center;
}

input::placeholder {
  color: #979797;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.title-font {
  font-family: $titleFont;
}
.text-font {
  font-family: $textFont;
}
.text-font-bold {
  font-family: $textFontBold;
}

button {
  font-family: $textFontBold;
}

.default-btn {
  color: #fff;
  height: 38px;
  background-color: #000000;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &:hover:not(:disabled) {
    background-color: #fff;
    color: #000;
    border-color: #000;
    cursor: pointer;
  }
  &:focus {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  &:disabled {
    opacity: 0.7;
    color: #fff;
    background-color: #000000;
  }

  svg {
    font-size: 18px;
    color: #fff;
  }
}

.form-input {
  margin-top: 1rem;
  width: 100%;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  input {
    width: 100%;
  }
}

// spin loading
.loading-inside-container {
  z-index: 99;
  display: flex;
  background: #fff;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  outline: 0;
  display: block;
  text-align: center;

  .loading-container {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .loading-img {
      width: 230px;
      height: 79px;
      margin:auto;
    }
  }

  
}
.error {
  font-size: 12px;
  margin-top: 5px;
  color: red;
}

.background-add-icon-to-screen {
  position: fixed;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: #1d8bfa;
  width: 100%;
  padding: 15px;
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    top: 0;
    bottom: unset;
  }

  .content {
    text-align: center;
    .title-add-icon-home-screen {
      font-size: 18px;
      color: #fff;
    }

    .action {
      margin-top: 20px;

      button {
        cursor: pointer;
        background-color: #000;
        color: #fff;
        outline: none;
        border: 1px solid #000;
        padding: 0.375rem 0.875rem;
        border-radius: 4px;
        width: 134px;
      }
      button.btn-cancel {
        margin-left: 10px;
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
  }
}

.btn-cancel {
  background-color: #fff;
  border-color: #000;
  color: #000;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  width: 94px;

  &:hover {
    background-color: #000;
    border-color: #fff;
    opacity: 0.7;
  }

  @media (max-width: 766px) {
    margin-top: 15px;
  }
}

.btn-ok {
  background-color: #1aae9f;
  border-color: #1aae9f;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  width: 94px;

  &:hover {
    color: #fff;
    opacity: 0.7;
  }

  @media (max-width: 766px) {
    margin-top: 15px;
  }
}

.cur {
  cursor: pointer;
}

.status-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .status-bar-left {
    .item {
      font-family: $textFontBold;
      width: 174px;
      padding: 10px;
      margin: 0 30px;
      text-align: center;
      cursor: pointer;

      .status-order-icon {
        width: 154px;
        height: 154px;
      }

      .progression {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #17ad9f;
        width: 133px;
        height: 133px;
        color: #fff;
        font-size: 44px;
        margin: 0 auto;
        border-radius: 50%;
      }
    }

    .progress-container {
      padding: 0 220px;

      .progress {
        height: 15px;
        border: 1px solid #000;
        .progress-bar {
          background-color: #17ad9f;
        }
      }
    }
  }
  .status-bar-right {
    .item {
      font-family: $textFontBold;
      width: 174px;
      padding: 10px;
      margin: 0 30px;
      text-align: center;

      .status-order-icon {
        width: 154px;
        height: 154px;
      }

      .progression {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #17ad9f;
        width: 133px;
        height: 133px;
        color: #fff;
        font-size: 44px;
        margin: 0 auto;
        border-radius: 50%;
      }
    }
  }
}

.change-language {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .btn-language {
    width: 38px;
    height: 40px;
    padding: 8px;
    border: 0 !important;
    outline: none !important;
    box-shadow: none;
  }
}

.link {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.topnav {
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-header {
    display: flex;
    
    .logo {
      width: 150px;
    }
  }

  a {
    float: left;
    color: #000;
    text-align: center;
    padding: 14px 16px 0px 16px;
    text-decoration: none;
    font-size: 17px;
    font-family: "CircularStd-Bold";
    border-bottom: solid 1px #fff;
    margin-right: 10px;
  }

  a:hover {
    border-bottom: solid 1px #000;
  }

  a.active {
    border-bottom: solid 1px #000;
  }

  .btn-logout {
    width: 90px;
    float: right;
  }
}
