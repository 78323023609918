#product-error {
  margin: 32px 0;
  font-family: 'CircularStd-Book';
  font-size: 20px;

  .feedback-container {
    margin-bottom: 30px;

    .selected-pr-pic {
      img {
        width: 196px;
        height: 196px;
      }

      .action {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 0.2px;
        color: #646464;
        margin-top: 20px;
        margin-bottom: 40px;
        white-space: nowrap;
      }
    }

    .bundled-products {
      .title {
        padding: unset;
        margin: unset;
        font-family: 'CircularStd-Book';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 15px;
        letter-spacing: 0.2px;
        color: #969696;
        text-align: left;
        margin-top: 30px;
      }

      .tooltip-title {
        cursor: pointer;
        svg {
          height: 17px;
          width: 17px;
          margin-bottom: 5px;
        }

        svg:hover {
          color: #316CF4;
        }
      }

      .bundled-product {
        margin-top: 16px;
        display: flex;

        .bundled-product-detail {
          .title {
            padding: unset;
            margin: unset;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 15px;
            letter-spacing: 0.2px;
            color: #101010;
            margin-bottom: 12px;
          }

          .pr-id {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 13px;
            color: #969696;
          }

          .action {
            .error-link {
              color: #BF5151;
            }

            .download-link {
              color: #969696;
              position: relative;

              svg {
                position: absolute;
                height: 6px;
                width: 12px;
                top: 6px;
                right: -13px;
              }
            }
          }
        }
      }

      img {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
  }

  .divider {
    width: 100px;
    margin: auto;
    border: solid 1px #000000;
    margin: auto;
    
    &--last {
      margin-bottom: 40px;
      border: unset;
    }
  }
  
  .default-btn {
    font-size: 20px;
    font-family: 'CircularStd-Book';
  }

  .key {
    font-weight: bold;
  }

  .product-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 53px 0;

    .section.sku {
      font-size: 30px;
    }
  }

  .title {
    font-weight: $textFontBold;
    font-family: 'Garamond Premier Pro Regular';
    line-height: 73px;
    font-size: 60px;
    padding-left: 32px;
    text-align: center;
  }

  .upload-title {
    margin-top: 80px;

    .upload-info {
      margin-bottom: 10px;
    }
  }

  .defect-container {
    width: 85%;
    margin-left: 10%;

    .numberical-order{
      position: absolute;
    }
    
  }

  .defect-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .section {
    font-weight: bold;
  }

  .link {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .defect-btn {
    background-color: #fff;
    margin: 16px 0;
    color: black;
    height: 60px;

    &:hover {
      background-color: #000000;
      color: #fff;
    }
  }

  .defect-btn.disabled {
    pointer-events:none;
  }

  .upload-btn {
    border: 0;
    font-weight: normal;
    font-size: 26;
    border: 1px solid #000;
  }

  .upload-btn.disabled {
    pointer-events:none;
  }

  .logo-icon {
    width: 133px;
    padding: 30px 40px;
  }

  .submit-btn {
    width: 346px;
    height: 60px;
    font-size: 20px;
    background-color: #18AE9F;
    text-transform: uppercase;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-color: #18AE9F;

    &:hover {
      background-color: #000000;
      border-color: #000000;
      color: #ffffff;
    }
  }

  .submit-btn.disabled {
    pointer-events:none;
  }

  .contest-btn {
    font-family: 'CircularStd-Bold';
    background-color: #ee5f5b;
    padding: 10px;
    text-transform: uppercase;
    border-color: #ee5f5b;
    cursor: pointer;
    color: #ffffff;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;

    &:hover {
      background-color: #ee5f5b;
      border-color: #ee5f5b;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    height: 520px;

    @media (max-width: 766px) {
      height: 250px;
      margin-bottom: 15px;
    }

    img.images {
      width: 100%;
      height: 100%;
   }
  }

  .view-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
  }

  .view-center.admin-actions-section {
    margin-top: 0px;
    flex-direction: column;

    .divider {
      margin-bottom: 25px;
    }
  }

  .numberical_order {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 44px;
    font-family: 'CircularStd-Book';
    color: #fff;
     background-color: #D8D8D8;
  }

  .bg_active {
    background-color: #18AE9F;
  }

  .admin-actions { 
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .admin-action-group {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 400px;
    align-items: center;
    margin: 0 7px 0 7px;
    position: relative;

    .divider {
      position: absolute;
      top: -35px;
    }

    .defect-btn {
      text-transform: uppercase;
    }

    .refused-btn:hover {
      background-color: #ee5f5b;
      border-color: #ee5f5b;
    }

    .approve-btn:hover {
      background-color: #18ae9f;
      border-color: #18ae9f;
    }
  }

  .disabled {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
  }

  .inactive {
    background-color: #d8d8d8;
    border-color: #d8d8d8;
  }

  .selected {
    background-color: black;
    color: white;
  }

  .note-section {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .note-area {
      height: 300px;
      border-radius: 5px;
    }

    &__text-align-left {
      text-align: left;
    }

    .ant-list-header {
      border-bottom: 1px solid #000;
    }

    .ant-comment-content-author {
      margin-bottom: 8px;
    }

    .ant-comment-content-author-name {
      font-size: 24px;
      padding-right: 8px;
    }

    .ant-list-header {
      font-size: 24px;
    }

    .ant-comment-content-author-time { 
      font-size: 20px;
    }

    .ant-comment-content-detail {
      font-size: 20px;
    }

    .ant-comment-inner {
      padding: 8px 0;
    }

    &.right {
      justify-content: unset;
      text-align: unset;
      float: right;
    }
  }

  .order-status {
    margin-top:93px;
    

    .status-section {
      background-color: #18ae9f;
      padding: 10px 0 10px 0;
      color: #fff;
      border-radius: 7px;

      b {
        font-weight: bold;
      }

      &--approved-state {
        font-size: 32px;
        text-align: center;
        font-family: 'CircularStd-Bold';
      }
      
      &--credited-state {
        font-size: 25px;
        display: flex;
        flex-direction: column;
        padding: 5px 15px 5px 15px;
    
      }

      a {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .admin-list-modal {
    .modal-body {
      padding: 10px 20px;
      text-align: center;
    }
    .admin-list {
      height: 400px;
      overflow-y: auto;
      width: 100%;
    }
  }
  
  .image-info {
    display: flex;
    flex-direction: column;
    text-align: center;

    .delete-link {
      color: #000000;
      cursor: pointer;
    }
  }

  .hide { 
    display: none;
  }

  .modal-navigation-group-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .nav-btn {
    width: 150px;
    text-transform: uppercase;
    font-family: 'CircularStd-Bold';
    font-size: 15px;
    padding-top: 10px;

    &:hover {
      background-color: #000000;
      color: #ffffff
    }
  }

  .size-chart {
    .modal-dialog-centered {
      width: 1024px;
      margin: auto;
      max-width: 1024px;

      .modal-body {
        padding: 10px 40px;
        img {
          width: 100%;
        }
      }
  
    }
  }

  .order-info {
    .admin-view {
      margin-top: 130px;

      .info {
        float: right;
        margin-right: 35px;
      }
    }
  }
}
